/*All the padding, margin, and clear helpers should go here*/
@at-root .p-0 {
    padding: 0px !important;
}
.p-sm {
    padding: 10px;
}
.p-md {
    padding: 20px;
}
.p-lg {
    padding: 40px;
}
.m-0 {
    margin: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}
.mb-md {
    margin-bottom: 8px !important;
}
.pointer {
    cursor: pointer !important;
}
/* Brand Page */
ul.brandSideMenu {
    list-style: none;
    padding: 0px;
    li {
        list-style: none;
        margin-left: 0px;
        font-weight: 600;
        color: #aaa;
        line-height: 2em;
        padding-left: 3px;
    }
    li > a.active {
        background-color: #1890ff !important;
        color: #fff !important;
        padding: 3px 5px;
    }
    li > a:hover {
        background-color: #1890ff !important;
        color: #fff !important;
        padding: 3px 5px;
    }
}

.ant-list {
    li.ant-list-item {
        padding-left: 0px !important;
    }
}

.ant-statistic {
    line-height: 1.1;
}

.mobileScreenshotWrapper {    
    svg {
        position: relative;
        left: 50%;
        margin-top: 25px;
        margin-left: -160px;
        z-index: 2;
        overflow:hidden;
    }
    .mobileScreenshotImage {
        img {
            width:340px;
        }
        height: 480px;
        width: 330px;
        margin: 85px auto;
        left: 50%;
        margin-left: -150px;
        z-index: 3;
        position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
        top:210px;
    }
}

.ant-menu-title-content {
    a {
        font-size:15px;
        color:#000;
        font-weight:500;
        font-family: Inter;
    }
}

.text-center {
    text-align: center !important;
  }

  .ant-layout {
    background-color: #FFF !important;
  }