body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-pro-sider-logo h1 {
  display: inline-block;
  height: 32px;
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 600;
  font-size: 1.7em;
  line-height: 32px;
  vertical-align: middle;
  animation: pro-layout-title-hide 0.3s;
}

/* AUTH LAYOUT STYLES */
.auth-container {
  height: 100vh;
  div.h-100 {
    height: 100%!important;
  }
  .ant-card-body {
    padding:20px;
  }
  .ant-card {
    box-sizing: border-box;
    padding: 0;
    color: #455560;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    background: #fff;
    border-radius: 0.625rem;
  }
  label {
    font-weight:500;
    color:#1a3353;
    font-size:14px;
  } 
}

.auth-background-style {
  backgroundimage: "url(/img/others/img-17.jpg)";
  backgroundrepeat: "no-repeat";
  backgroundsize: "cover";
}

.ant-card-cover {
  padding: 7px !important;
  /*height:300px;*/
  overflow: hidden;
}

.ant-card {
  /* Used for the browseEmails component thumbs */
  &.miniEmailCard {
    .ant-card-cover {
      height: 100px;
      padding: 7px !important;
      overflow: hidden;
    }
  }
  /* Used for the emailCards component thumbs */
  &.emailCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-card-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding:24px;
      .ant-card-meta-title {
        overflow: hidden;
        color: rgba(0,0,0,.85);
        font-weight: 500;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }


   
    .ant-card-cover {
      padding: 7px !important;
      height: 300px;
      overflow: hidden;
      img {
        transition-timing-function: ease-in-out;
        transition-duration: 0.5s; /* changes the speed scrolling back after hover*/
        width:100%;
        &:hover {
          margin-top: 300px; /* needs to match the height of image container box */
          transform: translate(
            0,
            -100%
          ); /* the transform property makes the magic happen. 
          The translate value is moving on the axes of x = 0 and y = -100%*/
          transition-timing-function: ease-in-out; /* W3 Schools tells us this 
          "Specifies a transition effect with a slow end (equivalent to cubic-bezier(0,0,0.58,1))" */
          transition-duration: 3s; /* change the hover scroll speed*/
        }
      }
    }

    .ant-card-actions {
      margin: 0;
      padding: 0;
      list-style: none;
      background: #fff;
      border-top: 1px solid #f0f0f0;
      >li {
        float: left;
        margin: 12px 0;
        color: rgba(0,0,0,.45);
        text-align: center;
    }
  }
  }
}



.ant-card {
  box-sizing: border-box;
  padding: 0;
  color: #333b41;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  /*border-radius: 0.625rem;*/
}

.ant-page-header {
  padding: 0px !important;
  /*
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      padding: 16px 24px;
      background-color: #fff;
      */
}

//Style tags that appear on cards
.cardTags {
  margin-top: 10px;
}

.colorBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  .swatch {
    width: 24px;
    height: 24px;
    background-color: #eee;
  }
  .label {
    font-size: 0.8em;
    color: #ccc;
  }
}

.swiper-slide {
  width: 80%;
}
